<!-- src/components/ContentList.vue -->

<template>
    <el-aside width="280px" class="sidebar">
      <div class="topbar">
        <button @click="showDialogAndResetForm">添加一个相位或内容</button>
        <div class="filter">
        <el-select v-model="filterByProgObj" placeholder="过滤器">
    <el-option
        v-for="(value, key) in mappings.prog_obj"
        :key="key"
        :label="value"
        :value="value"
    ></el-option>
      </el-select>


      </div>
      </div>

      

      <div class="scrollable-container">
          <ul>
            <li v-for="item in filteredContentList" :key="item.id" :data-id="item.id" @mouseenter="showButton = item.id" @mouseleave="showButton = null" @click="handleItemClick(item.id)" :class="{ 'selected-item': item.id === currentSelectedId }" >
            
              <div class="row">
                <span class="green-box small-text">{{ item.sord }}</span>
                <span class="blue-box small-text">{{ item.prog_method }}</span>
              </div>

              
              <div class="row normal-text-bold">
                <!-- 如果 horo == 'OBJ' -->
                <template v-if="item.horo === 'OBJ'">
                  {{ item.prog_obj }}
                  <span :class="getAspectClass(item.aspect)">&nbsp;{{ item.aspect }}&nbsp;</span>
                  {{ item.birth_point }}
                </template>

                <!-- 如果 horo == 'HOUSE' 或 horo == 'SIGN' -->
                <template v-else-if="item.horo === 'HOUSE' || item.horo === 'SIGN'">
                  {{ item.prog_obj }} <span style="color: #969696;">&nbsp;in&nbsp;</span> {{ item.birth_point }}
                </template>
              </div>

            </li>
          </ul>
        </div>

      <el-dialog v-model="centerDialogVisible" title="添加内容">
        <el-form ref="form" label-position="top">
          <el-form-item label="选择基本方法">
            <el-select v-model="selectedSord" class="selectIn" placeholder="选择单盘和双盘">
              <el-option
                v-for="(value, key) in mappings.sord"
                :key="key"
                :label="value"
                :value="value"
              ></el-option>
            </el-select>

            <el-select v-model="selectedProgmethod" class="selectIn" placeholder="选择行运方法">
              <el-option
                v-for="(value, key) in mappings.prog_method"
                :key="key"
                :label="value"
                :value="value"
              ></el-option>
            </el-select>

            <el-select v-model="selectedHoro" placeholder="选择本命盘点类型">
              <el-option
                v-for="(value, key) in mappings.horo"
                :key="key"
                :label="value"
                :value="value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="选点">
            <el-select v-model="selectedProgobj" class="selectIn" placeholder="选择行运点">
              <el-option
                v-for="(value, key) in mappings.prog_obj"
                :key="key"
                :label="value"
                :value="value"
              ></el-option>
            </el-select>
            
            
            <el-select v-model="selectedBirthPoint" placeholder="选择本命盘点">
              <el-option
                v-for="option in birthPointOptions"
                :key="option.key"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="相位">
            <el-select v-model="selectedAspect" :disabled="isAspectDisabled" placeholder="选择相位">
              <el-option
                v-for="(value, key) in mappings.aspect"
                :key="key"
                :label="value"
                :value="value"
              ></el-option>
            </el-select>
          </el-form-item>

        </el-form>
           
            <!-- 确认和取消按钮 -->
            <span class="dialog-footer">
              <el-button @click="centerDialogVisible = false">取消</el-button>
              <el-button type="primary" @click="addContentToDatabase">确认</el-button>
            </span>
      </el-dialog>
    </el-aside>
  </template>
  
  <script>
  export default {
    props: {
    type: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      filterByProgObj: null,
      contentList: [],
      showButton: null,
      centerDialogVisible: false,
      mappings: {},
      selectedAspect: null,
      selectedHoro: null,
      selectedBirthPoint: null,
      selectedProgmethod: null,
      selectedProgobj: null,
      selectedSord: null,
      currentSelectedId: null
    };
  },

  mounted() {
    console.log("Component mounted");
    this.fetchContentList();
    this.fetchMappings(); 
  },

  methods: {
    getAspectClass(aspect) {
      switch (aspect) {
    case 'Conjunction': return 'conjunction';
    case 'Sextile': return 'sextile';
    case 'Square': return 'square';
    case 'Trine': return 'trine';
    case 'Opposition': return 'opposition';
    default: return ''; 
      }
    },

    showDialogAndResetForm() {
    // 打开对话框
    this.centerDialogVisible = true;

    // 重置所有与表单相关的数据属性
    this.selectedSord = null;
    this.selectedProgmethod = null;
    this.selectedHoro = null;
    this.selectedProgobj = null;
    this.selectedBirthPoint = null;
    this.selectedAspect = null;
    }, 

    async fetchContentList() {
      try {
        const response = await fetch('https://astro-cw.le-way.cn/prod-api/lists');
        if (response.ok) {
          this.contentList = await response.json();
        } else {
          console.error('Failed to fetch content list:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching content list:', error);
      }
    },

    async fetchMappings() {
    try {
      const response = await fetch('https://astro-cw.le-way.cn/prod-api/mappings');
      if (response.ok) {
        this.mappings = await response.json();
        console.log("Fetched mappings:", this.mappings);
        
      } else {
        console.error('Failed to fetch mappings:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching mappings:', error);
    }
  },

  async addContentToDatabase() {
  try {
    const dataToSend = {
      sord: this.selectedSord,
      prog_method: this.selectedProgmethod,
      horo: this.selectedHoro,
      prog_obj: this.selectedProgobj,
      birth_point: this.selectedBirthPoint,
      aspect: this.selectedAspect || 'Conjunction'
    };

    console.log('Sending data:', dataToSend);


    const response = await fetch('https://astro-cw.le-way.cn/prod-api/lists', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataToSend)
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      console.log(jsonResponse.message);  // 打印成功消息

      this.centerDialogVisible = false;  // 关闭对话框
      await this.fetchContentList();  // 重新获取内容列表
    } else {
      console.error('Failed to add content:', response.statusText);
    }
  } catch (error) {
    console.error('Error adding content:', error);
  }
  },

  handleItemClick(listId) {
    this.currentSelectedId = listId;
    const selectedItem = this.contentList.find(item => item.id === listId);
    this.$emit('itemSelected', listId, selectedItem);
  }

  },

  computed: {
    birthPointOptions() {
      let options = [];
      switch (this.selectedHoro) {
        case 'OBJ':
          options = Object.entries(this.mappings.birth_point_obj).map(([key, value]) => ({ key, label: value, value }));
          break;
        case 'HOUSE':
          options = Object.entries(this.mappings.birth_point_house).map(([key, value]) => ({ key, label: value, value }));
          break;
        case 'SIGN':
          options = Object.entries(this.mappings.birth_point_sign).map(([key, value]) => ({ key, label: value, value }));
          break;
      }
      return options;
    },

    isAspectDisabled() {
      return this.selectedHoro === 'HOUSE' || this.selectedHoro === 'SIGN';
    }, 

    filteredContentList() {
        if (!this.filterByProgObj) return this.contentList;  // 如果没有筛选，返回所有内容
        return this.contentList.filter(item => item.prog_obj === this.filterByProgObj);
    }
  }
}
</script>
  
  <style>
  /* 你可以在此添加任何你需要的样式 */
  .sidebar {
    color: var(--el-text-color-primary);
    background-color: rgb(32,33,35);
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
  }

  .sidebar .filter {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .sidebar .topbar {
    position: sticky;
    top: 0;
    background-color: rgb(32,33,35);
    color: white;
    padding: 20px;
    z-index: 10;
  }

  .sidebar ul {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .sidebar ul li {
    list-style: none;
    padding: 15px 20px;
    width: 100%;
    color: white;
    
  }

  .sidebar ul li:hover {
    background-color: #1c1c1c;  }

  .sidebar .row {
    display: flex;
    margin-bottom: 10px;  /* 添加一些间距，使行与行之间有一些空间 */
}

.sidebar .selectIn {
    margin-right: 10px;  /* 添加一些间距，使选择器与选择器之间有一些空间 */
}
.small-text {
    font-size: 10px;
}

.normal-text-bold {
    font-size: 14px;
    font-weight: bold;
}
.green-box {
    background-color: green;
    padding: 5px 10px;  /* 内部间距，使内容不会紧贴边框 */
    color: white;  /* 如果需要的话，更改文字颜色 */

    /* 左侧圆角 */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.blue-box {
    background-color: blue;
    padding: 5px 10px;
    color: white;

    /* 右侧圆角 */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

  
.conjunction { color: yellow; }
.sextile { color: rgb(10, 255, 194); }
.square { color: rgb(253, 33, 33); }
.trine { color: rgb(43, 174, 255); }
.opposition { color: rgb(136, 24, 255); }

/* assets/styles.css or in your Vue component <style> block */
.selected-item {
  background-color: rgb(20, 21, 22); /* or any other color you prefer */
}

  </style>
  