<template>
  <div class="text-col">
    <div v-if="selectedItem" class="title">
            <div class="row">
                <span class="green-box small-text">{{ selectedItem.sord }}</span>
                <span class="blue-box small-text">{{ selectedItem.prog_method }}</span>
            </div>
            <br/>

            <div class="row normal-text-bold h-title">
                <template v-if="selectedItem.horo === 'OBJ'">
                    {{ selectedItem.prog_obj }}
                    <span :class="getAspectClass(selectedItem.aspect)">&nbsp;{{ selectedItem.aspect }}&nbsp;</span>
                    {{ selectedItem.birth_point }}
                </template>

                <template v-else-if="selectedItem.horo === 'HOUSE' || selectedItem.horo === 'SIGN'">
                    {{ selectedItem.prog_obj }} <span style="color: #969696;">&nbsp;in&nbsp;</span> {{ selectedItem.birth_point }}
                </template>
            </div>
          </div>

    <div v-for="item in contentItems" :key="item.id">
      <ContentDetail :detail="item" @contentUpdated="$emit('contentUpdated')" />
    </div>

    <!-- 新增内容的表单 -->
    <div v-if="addingNew">
      <div class="edit-mode">
      <label>Main Text:</label>
      <textarea v-model="newDetail.maintext"></textarea>
      <p v-if="error" style="color: red;">{{ error }}</p>

      <label>Comment:</label>
      <textarea class ="smallarea" v-model="newDetail.comment"></textarea>

      <label>Source:</label>
      <textarea class ="smallarea" v-model="newDetail.source"></textarea>

      <button :disabled="!!error" @click="addNewContent" style="margin-left:20px">Add</button> &nbsp;
      <button @click="cancelAddNew">Cancel</button>
    </div>
    </div>

    <button v-if="!addingNew" @click="toggleAddNewMode" style="margin-left:20px">Add New Content</button>
  </div>
</template>

<script>
import ContentDetail from './ContentDetail.vue';

export default {
  components: {
    ContentDetail
  },
  props: {
    contentItems: Array,
    selectedItem: Object,
    currentListId: Number
  },
  data() {
    return {
      addingNew: false,
      newDetail: {
        list_id: null,
        maintext: '',
        comment: '',
        source: ''
      }
    };
  },
  watch: {
    'newDetail.maintext'(newValue) {
      if (!newValue.trim()) {
        this.error = 'Main text cannot be empty!';
      } else {
        this.error = null;
      }
    }
  },

  methods: {
    getAspectClass(aspect) {
      switch (aspect) {
          case 'Conjunction': return 'conjunction';
          case 'Sextile': return 'sextile';
          case 'Square': return 'square';
          case 'Trine': return 'trine';
          case 'Opposition': return 'opposition';
          default: return ''; 
      }
    },

    toggleAddNewMode() {
      this.addingNew = true;
      this.newDetail.list_id = this.currentListId; // 使用当前列表的第一个 item 的 id 作为 list_id
    },

    cancelAddNew() {
      this.addingNew = false;
      this.newDetail = {
        list_id: null,
        maintext: '',
        comment: '',
        source: ''
      };
    },

    async addNewContent() {
      console.log('Sending data:', this.newDetail);
      try {
        const response = await fetch('https://astro-cw.le-way.cn/prod-api/contents', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.newDetail)
        });

        if (response.ok) {
          this.$emit('contentUpdated');
          this.cancelAddNew();
        } else {
          console.error('Failed to add new content:', response.statusText);
        }
      } catch (error) {
        console.error('Error adding new content:', error);
      }
    }
  }
}
</script>

<style>
.text-col {
  width: 800px;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  color: white;
}

.text-col .title {
  margin-bottom: 40px;
  margin-left: 20px;
}

.text-col .h-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.edit-mode textarea {
  width: 100%;
  height: 200px;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  margin-left:20px;
  font-family:Arial, Helvetica, sans-serif;
}

.edit-mode textarea.smallarea {
  width: 100%;
  height: 50px;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.edit-mode label {
  display: inline-block;
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left:20px;
}

</style>
