<template>
  <div class ="maintext-block">

    <template v-if="editMode">
      <div class="edit-mode">
      <div>
        <label>Main Text:</label>
        <textarea v-model="editableDetail.maintext"></textarea>
        <p v-if="error" style="color: red;">{{ error }}</p>
      </div>
      <div>
        <label>Comment:</label>
        <textarea v-model="editableDetail.comment" class="smallarea"></textarea>
      </div>
      <div>
        <label>Source:</label>
        <textarea v-model="editableDetail.source" class="smallarea"></textarea>
      </div>
    </div>
    </template>
    <template v-else>
      <p class="maintext">{{ detail.maintext }}</p>
      <hr>
      <p>Comment: {{ detail.comment || '' }}</p>
      <hr>
      <p>Source: {{ detail.source || '' }}</p>
      <hr>
    </template>

    <template v-if="editMode">
        <button @click="toggleEditMode">Cancel</button>
        <button :disabled="!!error" @click="updateContent">Save</button>
      </template>

      <div>
      <button v-if="!editMode" @click="toggleEditMode">Edit</button> &nbsp;
      <button v-if="!editMode" @click="deleteContent">Delete</button>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object
  },
  data() {
    return {
      editMode: false,
      error: null,
      editableDetail: {
        maintext: '',
        comment: '',
        source: ''
      }
    };
  },
  watch: {
    detail: {
      deep: true,
      handler() {
        this.editableDetail = { ...this.detail };
      }
    },
    'editableDetail.maintext'(newValue) {
      if (!newValue.trim()) {
        this.error = 'Main text cannot be empty!';
      } else {
        this.error = null;
      }
    }
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.editableDetail = { ...this.detail };
      }
    },
    async updateContent() {
      if (this.error) return;

      try {
        const response = await fetch(`https://astro-cw.le-way.cn/prod-api/contents/${this.detail.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.editableDetail)
        });
        if (response.ok) {
          this.editMode = false;
          this.$emit('contentUpdated');
        } else {
          console.error('Failed to update content:', response.statusText);
        }
      } catch (error) {
        console.error('Error updating content:', error);
      }
    },

    async deleteContent() {
      const confirmed = window.confirm("Are you sure you want to delete this content?");
      if (!confirmed) return;

      try {
        const response = await fetch(`https://astro-cw.le-way.cn/prod-api/contents/${this.detail.id}`, {
          method: 'DELETE'
        });

        if (response.ok) {
          this.$emit('contentUpdated');  // Notify the outer component to refresh the list
        } else {
          console.error('Failed to delete content:', response.statusText);
        }
      } catch (error) {
        console.error('Error deleting content:', error);
      }
    }
  }
}
</script>

<style scoped>
.maintext-block {
  margin: 20px;
  margin-bottom: 40px;
  padding: 30px;
  border: 1px solid #555;
  border-radius: 10px;
}

.maintext-block p {
  margin: 20px 0;
  font-size: 14px;
}

.maintext {
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px !important;
  line-height: 1.8 !important;
  margin-top: 0 !important;
}

hr {
    border: none;               /* 移除默认的边框 */
    height: 1px;               /* 设置线的高度 */
    background-color: #444;   /* 设置线的颜色 */
    margin: 20px 0;           /* 设置上下的间距 */
}

.edit-mode textarea {
  width: 100%;
  height: 200px;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-family:Arial, Helvetica, sans-serif;
}

.edit-mode textarea.smallarea {
  width: 100%;
  height: 50px;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.edit-mode label {
  display: inline-block;
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
</style> 
