<template>
  <el-container>
    <ContentList :type="'picker'" @itemSelected="fetchItemDetails" />
    <el-main>
      <ContentDetailsList 
    :contentItems="itemDetails" 
    :selectedItem="selectedItem"
    :currentListId="currentListId"
    @contentUpdated="fetchItemDetailsAgain" 
      />

    </el-main>
  </el-container>
</template>

<script>
import ContentList from '@/components/ContentList.vue';
import ContentDetailsList from '@/components/ContentDetailsList.vue';

export default {
  components: {
    ContentList,
    ContentDetailsList
  },
  data() {
    return {
      selectedItem: null,
      itemDetails: [],
      currentListId: null,
      itemCount: 0
    };
  },
  methods: {
    async fetchItemDetails(listId, selectedItem) {
      this.currentListId = listId;
      this.selectedItem = selectedItem; 
      await this.fetchItemDetailsAgain();
    },
    async fetchItemDetailsAgain() {
  try {
    const response = await fetch(`https://astro-cw.le-way.cn/prod-api/contents/${this.currentListId}`);
    if (response.ok) {
      const jsonResponse = await response.json();
      this.itemDetails = jsonResponse.data;
      this.itemCount = jsonResponse.count;
    } else {
      console.error('Failed to fetch item details:', response.statusText);
    }
  } catch (error) {
    console.error('Error fetching item details:', error);
  }
}
  }
}
</script>



<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

#app {
  height: 100%;
  display: flex;
  background-color: rgba(52,53,65);
}
</style>
  